import React from "react";

function CCTVPageComp() {
  return (
    <div className="container bg-dark min-vh-100 rounded-5 text-center">
      <h3 className="display-2 text-white text-center fw-semibold text-shadow-pop-top">
        System Engineering Group's
      </h3>
      <hr className="page-line" />
      <h1 className=" text-shadow-1 display-5 text-white fw-normal">
        CCTV Division
      </h1>
    </div>
  );
}

export default CCTVPageComp;
