import React, { useEffect, useState } from "react";
import { json, NavLink } from "react-router-dom";
import { nanoid } from "nanoid";
import axios, { formToJSON } from "axios";
const API_URL = "https://seg.a2hosted.com";

function ProductVendingPageComp() {
  const [productTitle, setProductTitle] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [productModel, setProductModel] = useState("");
  const [productAge, setProductAge] = useState("");
  const [productPower, setProductPower] = useState("");
  const [productWeight, setProductWeight] = useState("");
  const [productDesc, setProductDesc] = useState("");
  const [productFOB, setProductFOB] = useState("");
  const [productImgArray, setProductImgArray] = useState([]);

  const [vendingProducts, setVendingProducts] = useState([]);

  useEffect(() => {
    const url = `${API_URL}/ProductJson/VendingAndGaming`;
    
    axios
      .get(url)
      .then(function (response) {
        console.log(response.data);
        setVendingProducts(response.data);
      })
      .catch(function (error) {
        setVendingProducts([]);
      });
  }, []);

  return (
    <>
      <div className="container bg-dark min-vh-100 rounded-5 p-2">
        <h2 className="display-2 text-white text-center fw-semibold text-shadow-pop-top">
          Commercial Laundry Equipment
        </h2>
        <p className="fs-1 text-white text-center fw-semibold text-shadow-1">
          ~Vending & Games~
          <br />
          <NavLink to={"/LaundryEquipment"} className={"text-decoration-none"}>
            <span className="fw-semibold btn btn-large btn-primary box-glow-1 border-0">
              Laundry Stuff Page
            </span>
          </NavLink>{" "}
        </p>
        <hr className="page-line" />
        {/* Dexter Equipment */}
        <div className="row text-white text-center justify-content-center mt-2 gy-3">
          {vendingProducts.map((productItem, Index) => {
            return (
              <div key={nanoid()} className="col-12 col-lg-3">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  onClick={() => {
                    setProductTitle(Index + ": " + productItem.pTitle); //Product Title
                    setProductPrice(productItem.pPrice); //Product Price (Don't Include $)
                    setProductModel(productItem.pModel);
                    setProductAge(productItem.pAge);
                    setProductWeight(productItem.pWeight);
                    setProductPower(productItem.pPower);
                    setProductFOB(productItem.pFOB);
                    setProductDesc(productItem.pDesc); //Product Desc
                    setProductImgArray(productItem.pImgs); // Path Relative to Index.html in public folder (./images/products/NAME)
                  }}
                >
                  Sku: {Index}. {productItem.pTitle}
                </button>
              </div>
            );
          })}
        </div>

        {/* End of Products */}
      </div>
      {/* Pop up Window */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              {/* Popup Title */}
              <h1 className="modal-title fs-5 fw-bold" id="exampleModalLabel">
              Sku#{productTitle}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            {/* Popup Body */}
            <div className="modal-body">
              <p className="fs-5 fw-semibold">Price: ${productPrice}</p>
              <p className="fs-6 fw-semibold">Model: {productModel}</p>
              <p className="fs-6 fw-semibold">Age: {productAge}</p>
              <p className="fs-6 fw-semibold">Approx Weight:{productWeight}</p>
              <p className="fs-6 fw-semibold">Power Req: {productPower}</p>
              <p className="fs-6 fw-semibold">F.O.B: {productFOB}</p>
              <p className="fs-6 fw-semibold">Desc: {productDesc}</p>
              <p className="fs-6 fw-bolder">
                Contact For Shipping Quote and to Purchase.
              </p>
              <div id="carouselExample" className="carousel slide">
                <div className="carousel-inner">
                  {productImgArray.map((imgSrc, Index) => {
                    if (Index === 0) {
                      return (
                        <div key={nanoid()} className="carousel-item active">
                          <img
                            src={imgSrc}
                            className="d-block w-100"
                            alt="Product Img"
                          />
                        </div>
                      );
                    } else {
                      return (
                        <div key={nanoid()} className="carousel-item">
                          <img
                            src={imgSrc}
                            className="d-block w-100"
                            alt="Product Img"
                          />
                        </div>
                      );
                    }
                  })}
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExample"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  />
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExample"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  />
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductVendingPageComp