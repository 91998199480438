import React from 'react'
import "./css/header.css"

function HeaderComp() {
  return (
    <>
    <div className='text-white bg-dark p-3 text-center Header-Bg'>
        <img className='header-logo border-dark' src="#" alt="Logo" />
    </div>
    </>
  )
}

export default HeaderComp