import { Routes, Route } from "react-router-dom";

import HeaderComp from "./Components/HeaderComp";
import NavComp from "./Components/NavComp";
import HomePageComp from "./Components/pages/HomePageComp";
import LaundryEquipPageComp from "./Components/pages/LaundryEquipPageComp";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";


import WashersPageComp from "./Components/pages/subPages/WashersPageComp";
import DryersPageComp from "./Components/pages/subPages/DryersPageComp";
import ProductChangersPageComp from "./Components/pages/subPages/ProductChangersPageComp";
import ProductVendingPageComp from "./Components/pages/subPages/ProductVendingPageComp";
import ProductWaterHeatersPageComp from "./Components/pages/subPages/ProductWaterHeatersPageComp";
import FooterComp from "./Components/FooterComp";
import CCTVPageComp from "./Components/pages/CCTVPageComp";
import ComputersPageComp from "./Components/pages/ComputersPageComp";
import "./Components/css/app.css";
function App() {
  useEffect(() => {
    try {
      var myModal = document.querySelector(".modal-backdrop");
      var pageBody = document.querySelector("body");
      pageBody.classList.remove('modal-open')
      pageBody.style.removeProperty("overflow")
      pageBody.style.removeProperty("padding-right")
      myModal.remove()
      console.log("modal Closed");
    } catch (error) {
      console.log("modal Close Failed");
    }
  }, [useLocation()]);

  return (
    <>
      <div className="app-bg min-vh-100">
        <HeaderComp />
        <NavComp />
        <div className="p-3">
          
          <Routes>
            <Route path="/" element={<HomePageComp />} />
            <Route path="/TechDivisions/CCTV" element={<CCTVPageComp />} />
            <Route path="/TechDivisions/Computers" element={<ComputersPageComp />} />
            <Route
              path="/LaundryEquipment"
              element={<LaundryEquipPageComp />}
            />
            {/* Laundry Equipment Sub pages */}
            <Route path="/LaundryEquipment/CommercialWasherProducts" element={<WashersPageComp />} />
            <Route path="/LaundryEquipment/CommercialDryersProducts" element={<DryersPageComp />} />
            <Route path="/LaundryEquipment/CommercialChangersProducts" element={<ProductChangersPageComp />} />
            <Route path="/LaundryEquipment/CommercialVendingProducts" element={<ProductVendingPageComp />} />
            <Route path="/LaundryEquipment/WaterHeatersProducts" element={<ProductWaterHeatersPageComp />} />
          </Routes>
        </div>
        <FooterComp />
      </div>
    </>
  );
}

export default App;
