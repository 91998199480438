import React from "react";
import { NavLink } from "react-router-dom";
import "./css/navbar.css";

function NavComp() {
  return (
    <>
      <nav className="navbar navbar-expand-lg position-sticky top-0 bg-stone-1 navbar-dark border-top rounded-bottom-4 border-primary">
        <div className="container-fluid">
          <NavLink className={"navbar-brand fw-bold "} to={"/"}>
            SEG
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink className={"nav-link fw-semibold"} to={"/"}>
                  Home
                </NavLink>
              </li>
              {/* <li class="nav-item dropdown">
                <NavLink
                  className={"nav-link dropdown-toggle"}
                  to={"/TechDivisions"}
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Tech Divisions
                </NavLink>
                <ul class="dropdown-menu bg-dark">
                  <li>
                    <NavLink
                      className={"nav-link fw-semibold"}
                      to={"/TechDivisions/Computers"}
                    >
                      Computer Networking
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={"nav-link fw-semibold"}
                      to={"/TechDivisions/CCTV"}
                    >
                      Video Surveillance 
                    </NavLink>
                  </li>
                  
                </ul>
              </li> */}
              <li className="nav-item">
                <NavLink
                  className={"nav-link fw-semibold"}
                  to={"/LaundryEquipment"}
                >
                  Laundry Stuff
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavComp;
