import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { nanoid } from "nanoid";
import { GiWashingMachine, GiVendingMachine } from "react-icons/gi";
import { MdLocalLaundryService } from "react-icons/md";
import { RiWaterFlashFill } from "react-icons/ri";
import { BsCashCoin } from "react-icons/bs";


import "../css/laundryEquipment.css";

function LaundryEquipPageComp() {
  return (
    <>
      <div className="container bg-dark min-vh-100 rounded-5 p-2 text-center">
        <h2 className="display-2 text-white text-center fw-semibold text-shadow-pop-top">
          Commercial Laundry Equipment
        </h2>
        <hr className="page-line" />
        <div className="row gy-3">
          <div className="col-12 ">
            <NavLink
              className={
                "btn btn-primary fw-semibold text-white box-glow-1 border-0 text-shadow-1" 
              }
              to={"/LaundryEquipment/CommercialWasherProducts"}
            >
              Commercial Washers{" "}
              <span className="fs-4">
                <GiWashingMachine />
              </span>
            </NavLink>
          </div>
          <div className="col-12 ">
            <NavLink
              className={
                "btn btn-primary fw-semibold text-white box-glow-1 border-0 text-shadow-1"
              }
              to={"/LaundryEquipment/CommercialDryersProducts"}
            >
              Commercial Dryers{" "}
              <span className="fs-4">
                <MdLocalLaundryService />
              </span>
            </NavLink>
          </div>
          <div className="col-12 ">
            <NavLink
              className={
                "btn btn-primary fw-semibold text-white box-glow-1 border-0 text-shadow-1"
              }
              to={"/LaundryEquipment/CommercialChangersProducts"}
            >
              Money Changers{" "}
              <span className="fs-4">
                <BsCashCoin />
              </span>
            </NavLink>
          </div>
          <div className="col-12 ">
            <NavLink
              className={
                "btn btn-primary fw-semibold text-white box-glow-1 border-0 text-shadow-1"
              }
              to={"/LaundryEquipment/CommercialVendingProducts"}
            >
              Vending & Game Machines{" "}
              <span className="fs-4">
                <GiVendingMachine />
              </span>
            </NavLink>
          </div>
          <div className="col-12 ">
            <NavLink
              className={
                "btn btn-primary fw-semibold text-white box-glow-1 border-0 text-shadow-1"
              }
              to={"/LaundryEquipment/WaterHeatersProducts"}
            >
              Water Heaters{" "}
              <span className="fs-4">
                <RiWaterFlashFill />
              </span>
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
}

export default LaundryEquipPageComp;
