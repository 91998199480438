import React from "react";

function HomePageComp() {
  return (
    <>
      <div className="container bg-dark min-vh-100 rounded-5 text-center">
        <h3 className="display-2 text-white text-center fw-semibold text-shadow-pop-top">System Engineering Group Inc</h3>
        <hr className="page-line" />
        <h1 className=" text-shadow-1 display-5 text-white fw-normal">Contact Info</h1>
        <p className="fs-4 text-shadow-1  text-light">(Contact for a Quote or to Buy)</p>
        <p className="fs-3 text-shadow-1  text-white m-1">President: <span className="text-info">Tom Weinrich</span></p>
        <p className="fs-3 text-shadow-1  text-white m-1">Phone#: <a className="link-info" href="tel:3149740672">314-974-0672</a></p>
        <p className="fs-3 text-shadow-1  text-white m-1">Email: <a className="link-info" href="mailto:tom@segotw.com">Tom@segotw.com</a></p>
      </div>
    </>
  );
}

export default HomePageComp;
