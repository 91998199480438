import React from 'react'
import "./css/app.css"
function FooterComp() {
  return (
    <div className='bg-dark text-white border-top border-1 border-primary'>
      <div className="container">
        <div className="row text-center justify-content-center">
          <div className="col-6">&copy;{new Date().getFullYear()} System Engineering Group</div>
          <div className="col-6">Website Designed & Managed By: Jacob Weinrich</div>
        </div>
      </div>
    </div>
  )
}

export default FooterComp